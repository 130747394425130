<template>
  <div>
    <v-container
      ><p>Arrange the following atomic orbitals from lowest to highest energy</p></v-container
    >
    <v-form>
      <drag-to-rank-input
        v-model="inputs.orbitals"
        :items="questions"
        class="mb-0"
        prepend-text="Highest Energy"
        append-text="Lowest Energy"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import DragToRankInput from '@/tasks/components/inputs/DragToRankInput.vue';

export default {
  name: 'ArrangeAtomicOrbitals',
  components: {
    DragToRankInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        orbitals: [],
      },
      questions: ['2p', '3d', '4s', '1s', '3p', '2s', '4p', '3s'],
    };
  },
};
</script>

<style scoped></style>
